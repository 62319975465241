// import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../shared/responseModal';
export default {
  name: 'vendorDetails',
  props: ['vendorAllData', 'rfphdrid'],
  components: {
    responseModal
  },
  data() {
    return {
      showValueSetModal: false,
      legalEntity: {
        value: null,
        text: null
      },
      vendorData: [
        {
          vendor_type: 'Existing',
          vendor_code: null,
          name: null,
          email: null,
          mob: null,
          pan_no: null,
          party_req_id: null,
          user_id: null,
          rfp_vendor_id: 0,
          delete_flag: false
        }
      ],
      vendorFields: [
        {
          key: 'add_row',
          label: 'Add',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'col-medium'
        },
        // {
        //   key: 'remove_row',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        // { key: 'vendor_type', class: 'text-center' },
        {
          key: 'vendor_code',
          class: 'text-center'
        },
        {
          key: 'name',
          class: 'text-center'
        },
        {
          key: 'email',
          class: 'text-center'
        },
        {
          key: 'mob',
          class: 'text-center'
        },
        {
          key: 'pan_no',
          class: 'text-center'
        },
        // {
        //   key: 'verify_pan',
        //   class: 'text-center'
        // },
        {
          key: 'party_req_id',
          class: 'd-none'
        },
        {
          key: 'user_id',
          class: 'd-none'
        }
      ],
      options: [
        { item: 'Existing', name: 'Existing' },
        { item: 'New', name: 'New' }
      ],
      index: null,
      rfpHeaderId: null,
      verifyPanFlag: false,
      editMode: true,
      roundStatus: null,
      rfpClosedFlag: false,
      rfpStatus: {
        value: null,
        text: null
      },
      popoverContent: null
    };
  },
  mounted() {
    if (this.vendorAllData && this.vendorAllData.length > 0) {
      this.getParentToChildData(this.vendorAllData);
      this.editMode = false;
    }
    this.rfpHeaderId = this.rfphdrid;
  },
  created() {
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('locationDets', locationDets => {
      this.locationDets = locationDets;
    });
    this.eventBus.$on('roundStatus', roundStatus => {
      this.roundStatus = roundStatus;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
    this.eventBus.$on('rfpStatus', rfpStatus => {
      this.rfpStatus = rfpStatus;
    });
  },
  methods: {
    addNewRow() {
      // this function adds a new row on click of add button inside the table
      this.vendorData.push({
        vendor_type: 'Existing',
        vendor_code: null,
        name: null,
        email: null,
        mob: null,
        pan_no: null,
        party_req_id: null,
        user_id: null,
        rfp_vendor_id: 0,
        delete_flag: false
      });
    },
    removeRow(index) {
      // this function removes the row on click of add button inside the tables
      if (this.vendorData.length === 1) {
        this.vendorData = [
          {
            vendor_type: 'Existing',
            vendor_code: null,
            name: null,
            email: null,
            mob: null,
            pan_no: null,
            party_req_id: null,
            user_id: null,
            rfp_vendor_id: 0,
            delete_flag: false
          }
        ];
      } else {
        this.vendorData.splice(index, 1);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.index = index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      let count = 0;
      for (let i of this.vendorData) {
        if (item.vendor_email === i.email) {
          count++;
        }
      }
      if (count > 0) {
        alert('Vendor already exist');
        this.vendorData.splice(this.index, 1);
      } else {
        this.vendorData[this.index].vendor_code = item.vendor_code;
        this.vendorData[this.index].name = item.vendor_name;
        this.vendorData[this.index].email = item.vendor_email;
        this.vendorData[this.index].mob = item.vendor_mobile;
        this.vendorData[this.index].pan_no = item.pan_no;
        this.vendorData[this.index].party_req_id = item.party_req_id;
        this.vendorData[this.index].user_id = item.user_id;
        this.vendorData[this.index].delete_flag = false;
        this.eventBus.$emit('vendors', this.vendorData);
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RFP_VENDORS') {
        this.vendorData[this.index].vendor_code = null;
        this.vendorData[this.index].name = null;
        this.vendorData[this.index].email = null;
        this.vendorData[this.index].mob = null;
        this.vendorData[this.index].pan_no = null;
        this.vendorData[this.index].party_req_id = null;
        this.vendorData[this.index].user_id = null;
        this.vendorData[this.index].delete_flag = false;
      }
    },
    postVendors() {
      let vendordetails = [];
      let count = 0;
      for (let i of this.vendorData) {
        if ((i.name === null || i.email === null) && !i.delete_flag) {
          count = count + 1;
        } else {
          vendordetails.push({
            rfp_vendor_id: i.rfp_vendor_id,
            party_req_id: i.party_req_id,
            pan_no: i.pan_no,
            email: i.email,
            name: i.name,
            mob: i.mob,
            user_id: i.user_id,
            is_new_vendor: i.vendor_type === 'New' ? true : false,
            vendor_code: +i.vendor_code,
            delete_flag: i.delete_flag
          });
        }
      }
      if (count > 0) {
        alert('Please fill in the mandatory fields!');
      } else {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          rfp_vendor_dtls: vendordetails
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/postVendorData', payload)
          .then(response => {
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.getVendors();
              this.$emit('getHeaderData');
              this.editMode = false;
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
            }
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },
    getVendors() {
      let payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getVendorData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.vendorData = result;
            if (this.vendorData.length === 0) {
              this.vendorData.push({
                vendor_type: 'Existing',
                vendor_code: null,
                name: null,
                email: null,
                mob: null,
                pan_no: null,
                party_req_id: null,
                user_id: null,
                rfp_vendor_id: 0,
                delete_flag: false
              });
            }
            this.eventBus.$emit('vendorData', this.vendorData);
            this.editMode = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000,
      });
    },
    verifyPan(pan) {
      let payload = {
        id_number: pan
      };
      this.loading = true;
      this.$store
        .dispatch('intWorkflow/checkPanVerify', payload)
        .then(response => {
          if (response.status === 200) {
            this.verifyPanFlag = true;
            // this.responseMsg = response.data.data.data.full_name;
            // this.makeToast(this.responseMsg, 'success');
            this.oracleVerifyPan(pan);
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    oracleVerifyPan(pan) {
      let payload = {
        REG_DETAILS: {
          REG_DETAILS_ITEM: [
            {
              REG_TYPE: 'PAN',
              REG_NUMBER: pan
            }
          ]
        }
      };

      this.loading = true;
      this.$store
        .dispatch('intWorkflow/isVendorExist', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = 'PAN Verified Successfully!';
            this.makeToast(this.responseMsg, 'success');
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    checkDuplicate(code, item) {
      let count = 0;
      for (let i of this.vendorData) {
        if (i !== item && i.vendor_code === code && i.vendor_code !== null) {
          count = count + 1;
        }
      }
      if (count > 0) {
        alert('Duplicate Code');
      }
    },
    handleEditMode() {
      this.editMode = !this.editMode;
    },
    getParentToChildData(data) {
      if (data.length > 0) {
        this.vendorData = data;
        this.eventBus.$emit('vendorData', this.vendorData);
      }
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    deleteVendor(item) {
      item.delete_flag = !item.delete_flag;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('headerId');
  }
};
