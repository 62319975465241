export default {
  name: 'initiateNegotiation',
  props: ['vendorData', 'rfpHeaderId', 'headerAllData'],
  data() {
    return {
      loading: false,
      initiateNegotiationData: [],
      initiateNegotiationFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'vendor_code',
          class: 'col-fix text-center'
        },
        {
          key: 'name',
          class: 'col-fix text-center'
        },
        {
          key: 'email',
          class: 'col-fix text-center'
        },
        {
          key: 'mob',
          class: 'col-fix text-center'
        },
        {
          key: 'pan_no',
          class: 'col-fix text-center'
        }
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      rfpheaderid: null,
      status: 'OPEN',
      negotiationHeaderId: null,
      roundData: [],
      rfpInitiateBidFlag: false
    };
  },
  mounted() {
    this.initiateNegotiationData = this.vendorData;
    if (this.rfpHeaderId) {
      this.rfpheaderid = this.rfpHeaderId;
    }
    this.selectAllBoxChecked(false);
  },
  created() {
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpheaderid = rfpHeaderId;
    });
  },
  methods: {
    selectBoxChecked(flag, index, item, email) {
      this.initiateNegotiationData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.vendor_email !== email
        );
        this.selectAllCheckBox = false;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.initiateNegotiationData = this.initiateNegotiationData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
        this.selectedRow = this.initiateNegotiationData;
      } else {
        this.initiateNegotiationData = this.initiateNegotiationData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
        this.selectedRow = [];
      }
    },
    postNegotiationDetailsData() {
      let vendor = [];
      for (let i of this.selectedRow) {
        vendor.push({
          negotiation_vendor_id: 0,
          rfp_vendor_id: i.rfp_vendor_id
        });
      }
      let payload = {
        negotiation_hdr_id: 0,
        rfp_header_id: this.rfpheaderid,
        negotiation_seq: 1,
        status: this.status,
        negotiation_vendor_dtls: vendor
      };
      this.$store
        .dispatch('rfp/postNegotiationDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.negotiationHeaderId = response.data.data.negotiation_hdr_id;
            // this.getNegotiationDetailsData();
            this.getAllRfpData();
          } else {
            this.loading = false;
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getNegotiationDetailsData() {
      let payload = {
        negotiation_header_id: this.negotiationHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getNegotiationDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.roundData.push({
              negotiation_seq: response.data.data.negotiation_seq,
              start_date: response.data.data.start_date,
              end_date: response.data.data.end_date,
              status: response.data.data.status,
              negotiation_hdr_id: response.data.data.negotiation_hdr_id,
              rfp_header_id: response.data.data.rfp_header_id
            });
            this.rfpInitiateBidFlag = true;
            this.eventBus.$emit('rfpInitiateBidFlag', this.rfpInitiateBidFlag);
          }
          this.eventBus.$emit('roundData', this.roundData);
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: "custom-toast",
        autoHideDelay: 2000,
      });
    },
    getAllRfpData() {
      const payload = {
        headerId: this.rfpheaderid
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let approvalstatus = response.data.data.approval_status;
            this.eventBus.$emit('approvalstatus', approvalstatus);
            let result = response.data.data.rfp_negotiation_dtls;
            this.roundData = result;
            this.eventBus.$emit('roundData', this.roundData);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  beforeDestroy() {
    this.eventBus.$off('headerId');
    this.eventBus.$off('rfpInitiateBidFlag');
    this.eventBus.$off('approvalstatus');
  }
};
