import appStrings from '@/app/utility/string.utility';

export default {
  name: 'boqAttributes',
  props: [
    'rfphdrid',
    'boqId',
    'headerAllData',
    'rfpInitiateBidFlag',
    'rfpClosedFlag',
    'boqIndex'
  ],
  data() {
    return {
      editMode: true,
      boqAttributeData: [
        {
          attribute_code: null,
          attribute_description: null,
          attribute_specification: null,
          delete_flag: false,
          boq_attribute_id: 0
        }
      ],
      boqAttributeFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        { key: 'add', label: 'Add', stickyColumn: true, class: 'text-center' },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'col-fix-medium'
        },
        // {
        //   key: 'remove',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'attribute_code',
          label: 'Attribute Code',
          class: 'text-center'
        },
        {
          key: 'attribute_description',
          label: 'Attribute Description',
          class: 'text-center'
        },
        {
          // key: 'attribute_specification',
          // label: 'Attribute Specification',
          // class: 'text-center'
        },
      ],
      headerId: null,
      rfp_item_id: null,
      attributeDataFlag: false,
      boqAttributeDataFlag: false,
      parent_value_set_id: null,
      showValueSetModal: false,
      vsetCode: null,
      index: null,
      copyAttributeData: [],
      copyAttributeFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'boq_line_code',
          class: 'col-fix text-center'
        },
        {
          key: 'boq_description',
          class: 'col-fix text-center'
        }
      ],
      showOpenCopyAttributesModal: false,
      selectedRow: [],
      selectAllCheckBox: false,
      popoverContent: null
    };
  },
  mounted() {
    this.headerId = this.rfphdrid;
    this.boq_id = this.boq_id;
    this.getBoqAttributeData();
  },
  methods: {
    addAttribute() {
      this.boqAttributeData.push({
        attribute_code: null,
        attribute_description: null,
        attribute_specification: null,
        delete_flag: false,
        boq_attribute_id: 0
      });
    },
    removeAttribute(index) {
      this.boqAttributeData.splice(index, 1);
    },
    postBoqAttributeData() {
      if (this.boqId) {
        let boqattributedata = [];
        let count = 0;
        for (let i of this.boqAttributeData) {
          if ((i.attribute_code === null || i.attribute_description === null || i.attribute_description === "") && !i.delete_flag) {
            count++;
          }
          boqattributedata.push({
            boq_attribute_id: i.boq_attribute_id,
            boq_attribute_code: i.attribute_code,
            boq_attribute_description: i.attribute_description,
            delete_flag: i.delete_flag
          });
        }
        if (count > 0) {
          alert('Please fill in mandatory fields');
        } else {
          let payload = {
            rfp_header_id: this.rfphdrid,
            boq_id: this.boqId,
            rfp_boq_attribute_dtls: boqattributedata
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/postBoqAttributeDetails', payload)
            .then(response => {
              if (response.status === 200) {
                this.responseMsg = response.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.getBoqAttributeData();
              } else {
                this.responseMsg = response.response.data.message;
                this.makeToast(this.responseMsg, 'danger'); 
              }
            })
            .catch(err => {
              this.loading = false;
              this.makeToast(err.message, 'danger');
            });
        }
      } else {
        alert('Bid id not found!');
      }
    },
    getBoqAttributeData() {
      let payload = {
        boq_id: this.boqId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getBoqAttributeDetails', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.boqAttributeData = result;
            this.boqAttributeDataFlag = true;
            this.eventBus.$emit(
              'boqAttributeDataFlag',
              this.boqAttributeDataFlag
            );
            if (result.length === 0) {
              this.boqAttributeData.push({
                attribute_code: null,
                attribute_description: null,
                attribute_specification: null,
                delete_flag: false,
                boq_attribute_id: 0
              });
              this.editMode = true;
            } else {
              this.editMode = false;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
        appendToast: true,
        toastClass: "custom-toast",
        autoHideDelay: 2000,
      });
    },
    deleteBoqAttribute(item) {
      item.delete_flag = !item.delete_flag;
    },
    alterEditMode() {
      this.editMode = !this.editMode;
    },
    openValueSetModal(vsetCode, index) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.index = index;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RFP_ATTRIBUTES) {
        this.boqAttributeData[this.index].attribute_code = item.value_code;
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    copyAttributesModal(flag) {
      for (let i of this.copyAttributeData) {
        i.selectBox = false;
      }
      this.selectedRow = [];
      this.showOpenCopyAttributesModal = flag;
    },
    copyAttribute() {
      const payload = {
        headerId: this.rfphdrid
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.copyAttributeData = result.rfp_boq_dtls;
            this.copyAttributeData = this.copyAttributeData.filter(
              el => el.rfp_boq_attribute_dtls.length === 0
            );
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      this.showOpenCopyAttributesModal = true;
    },
    selectBoxChecked(flag, index, item, boqId) {
      this.copyAttributeData[index].selectBox = flag;
      if (flag) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(el => el.boq_id !== boqId);
        this.selectAllCheckBox = false;
      }
    },

    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.copyAttributeData = this.copyAttributeData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.copyAttributeData;
      } else {
        this.copyAttributeData = this.copyAttributeData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
      }
    },
    copyAttributesToAllItems() {
      let copyItemId = [];
      for (let i of this.selectedRow) {
        copyItemId.push({
          to_boq_id: i.boq_id
        });
      }
      let payload = {
        rfp_header_id: this.headerId,
        from_boq_id: this.boqId,
        rfp_boq_copy_atr_dtls: copyItemId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postBoqCopyAttributes', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.showOpenCopyAttributesModal = false;
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    }
  }
};
